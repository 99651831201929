
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
.partner-container {
	padding-top: px2rem(100);
	background: white;
	position: relative;
	overflow: hidden;

	.title {
		display: flex;
		justify-content: flex-end;
		font-size: px2rem(48);
		font-weight: bold;
		color: #333333;

		.title-value {
			&::after {
				display: block;
				content: '';
				width: 100%;
				height: 15px;
				background: #0F52FB;
				margin-top: 6px;
			}
		}
	}

	.sub-title {
		font-size: px2rem(18);
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: flex-end;
		padding: px2rem(26) 0 px2rem(50) 0;
	}

	.description {
		font-size: px2rem(24);
		font-weight: 500;
		color: #666666;
		display: flex;
		justify-content: flex-end;
	}

	.bg-black {
		position: absolute;
		top: -140px;
		left: 0;
		height: 200%;
		width: 300%;
		z-index: -1;
		transform: translateX(-40%) translateY(-1%) rotate(5deg);
		background: #1A1A1A;
	}

	.partner-list {
		min-height: 500px;
		margin-top: 250px;
		padding-bottom: 50px;/* no */
		position: relative;
		z-index: 1;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-auto-rows: 180px;
		gap: 40px;

		.partner-item {
			width: 100%;
			height: 100%;
			background: #1A1A1A;
			border: 1px solid #0F52FB;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			.logo.hover {
				display: none;
			}
			&:hover {
				background: white;
				.logo {
					display: none;
				}
				.logo.hover {
					display: block;
				}
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				right: -10px;
				top: -10px;
				background: #0F52FB;
				z-index: -1;
			}

			&.empty {
				background: none;
				border: none;

				&::before {
					display: none;
				}
			}
		}
	}

	.more {
		background: #0F52FB;
		border-radius: 4px 4px 4px 4px;
		padding: 10px 20px;/* no */
		white-space: nowrap;
		opacity: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;
		font-weight: 400;
		color: #FFFDFD;
		cursor: pointer;
		box-sizing: border-box;
		img {
			width: 32px!important;
			height: 12px!important;
		}
	}
}
@media screen and (max-width: 1440px) {
	.partner-container {
		.more {
			padding: 5px 10px;/* no */
		}
		.partner-list {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
@media screen and (max-width: 1100px) {
	.partner-container {
		.more {
			padding: 5px 10px;/* no */
		}
		.partner-list {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}
@media screen and (max-width: 767px) {
	.partner-container {
		.title {
			font-size: 24px;
		}
		.more {
			padding: 5px 10px;/* no */
		}
		.partner-list {
			gap: 20px;
			margin-top: 80px;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 120px;
			min-height: unset;
			padding-bottom: 20px;
			.bg-black {
				top: -50px;
			}
		}
		.more {
			padding: 10px 20px;/* no */
			white-space: nowrap;
			opacity: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			font-weight: 400;
			color: #FFFDFD;
			cursor: pointer;
			box-sizing: border-box;
			img {
				width: 15px!important;
			}
		}
	}
}
@media screen and (max-width: 600px) {
	.partner-container {
		.partner-list {
			gap: 20px;
			margin-top: 80px;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 80px;
			min-height: unset;
			padding-bottom: 20px;
		}
	}
}
