
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
.delivery-checklist {
	background: white;
	padding-top: px2rem(100);
	padding-bottom: px2rem(128);
	.main {
		display: flex;
		flex-direction: column;
	}

	.dc-title {
		align-self: center;
		font-size: 2rem;
		font-weight: bold;
		color: #333333;
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 15px;
			background: #0F52FB;
			margin-top: 6px;
		}
	}
	.dc-subtitle {
		align-self: center;
		font-size: px2rem(18);
		font-weight: 400;
		color: #999999;
		margin-top: px2rem(26);
	}
	.dc-description {
		font-size: 1rem;
		font-weight: 500;
		color: #666666;
		margin-top: px2rem(50);
		align-self: center;
	}

	.dc-list {
		margin-top: px2rem(116);
		display: grid;
		grid-template-columns: repeat(5, 180px);
		grid-auto-rows: 240px;
		justify-content: space-between;
		row-gap: 40px;
		.list-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.item-card {
				width: 180px;
				height: 180px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				.blur {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					backdrop-filter: blur(12px);
					transform: translate(-12px, 12px);
					border: 3px solid #0F52FB;
					background: rgba(255,255,255,.2);
					box-sizing: border-box;
				}
				.bg {
					background: #0F52FB;
					position: absolute;
					width: 100%;
					height: 100%;
				}
				img {
					width: 50%;
					height: 50%;
					object-fit: contain;
					z-index: 1;
					transform: translate(-12px, 12px);
				}
			}
			.item-title {
				margin-top: 1rem;
				font-size: 1rem;
				font-weight: bold;
				color: #666666;
				white-space: nowrap;
				transform: translateX(-12px);
			}
		}
	}
}
@media screen and (max-width: 1100px){
	.delivery-checklist {
		.dc-list {
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: auto;
			column-gap: 40px;
			.list-item {
				&:last-child{
					grid-column: 2;
				}
				.item-card {
					aspect-ratio: 1;
					flex-shrink: 0;
					.blur {
						transform: translate(-12px, 12px);
					}
				}
				.item-title {
					font-size: 18px;
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.delivery-checklist {
		padding-top: px2rem(50);
		padding-bottom: px2rem(50);
		.dc-title{
			font-size: 24px;
		}
		.dc-description {
			font-size: 16px;
		}
		.dc-list {
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: auto;
			column-gap: 0;
			margin-top: px2rem(60);
			.list-item {
				transform: scale(0.9);
				&:last-child{
					grid-column: 2;
				}
				.item-card {
					aspect-ratio: 1;
					width: 120px;
					height: 120px;
					flex-shrink: 0;
					.blur {
						transform: translate(-12px, 12px);
					}
				}
				.item-title {
					font-size: 18px;
				}
			}
		}
	}
}
