
.list {
  text-align: center;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  justify-content: center;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  .process-container {
    text-align: center;
    display: flex;
    align-items: flex-start;
    // @media screen and (min-width: 1920px) {
    //   flex: 1;
    // }
  }
  .item {
    margin-bottom: 75px;
    .top {
      width: 150px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
      margin-bottom: 30px;
      .top-icon {
        width: 50px;
      }
    }
    .process-strip {
      background: #cde5fb;
      height: 32px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 44px;
      .strip-icon {
        width: 40px;
      }
    }
    .title {
      color: #3384e8;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 24px;
    }
    .detail {
      line-height: 1;
      .detail-item {
        color: #666666;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }
  .arrow {
    width: 100px;
    @media screen and (min-width: 1920px) {
      flex: 1;
    }
    .arrow-box {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      padding: 0 16px;

      .arrow-icon {
        width: 69px;
      }
    }
    .arrow-strip {
      background: #cde5fb;
      height: 32px;
      transform: scale(1.3, 1);
    }
  }
}
