
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
.footer-container {
	background:rgba(50, 53, 55, 1) url(../asset/index/index_40.png) no-repeat center/cover;
	.nav-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 120px;

		.navs {
			height: 100%;
			padding: 0 43px;
			cursor: pointer;
			font-size: 1rem;
			font-weight: bold;
			color: #FFFDFD;
			display: flex;
			justify-content: center;
			align-items: center;
			white-space: nowrap;

			&.selected {
				background: #0F52FB;
			}
		}
	}

	.ego {
		position: relative;
		//border-top: 1px solid #707070;
	}

	.container {
		line-height: 1;
		/* no */
		position: relative;
		z-index: 2;
	}

	.content {
		width: 100%;
		margin: 0 auto;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: white;
	}

	.company-name {
		color: rgba(255, 255, 255, 1);
		font-size:24px;
		font-weight: bold;
		line-height: 1;
		margin-top: 40px;
	}

	.company-theory {
		font-size: px2rem(18);
		font-weight: 400;
		color: rgba(255, 255, 255, 0.6);
		margin-top: px2rem(23);
	}

	.contact-container {
		margin-top: px2rem(20);
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		width: 100%;
		&.is-person-empty {
			grid-template-columns: auto auto;
		}
	}

	.contact-box {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: rgba(255, 255, 255, .6);
		font-size: px2rem(18);
		padding-top: 30px;

		.tel-title {
			font-size: px2rem(14);
			font-weight: bold;
			color: white;
			margin-bottom: 20px;
		}

		.tel-value {
			font-size: 24px;
			font-weight: bold;
			color: white;
		}

		.email {
			white-space: nowrap;
		}
	}

	.contact-person {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 px2rem(50);
		justify-self: center;
		row-gap: px2rem(30);
		/* no */

		.person {
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: px2rem(17);
			/* no */
			color: rgba(255, 255, 255, 0.6);
			font-size: px2rem(14);
			column-gap: px2rem(80);

			.name {
				font-weight: 400;
				grid-column-end: span 2;
				font-size: px2rem(20);
			}

			img {
				width: 20px;
				/* no */
				height: 20px;
				/* no */
			}

			.qq,
			.wx {
				display: flex;
				align-items: center;
				white-space: nowrap;
				font-size: px2rem(16);
			}
		}
	}

	.contact-qrcode {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.qr-code-image {
			width: 125px;
			height: 125px;
			background: white;
		}

		.qr-code-title {
			font-size: px2rem(24);
			font-weight: 500;
			color: #FFFFFF;
			line-height: 2.5;
			/* no */
		}

		.qr-code-sub-title {
			font-size: px2rem(18);
			font-weight: 400;
			color: rgba(255, 255, 255, 0.6);
		}
	}

	.left {
		float: left;
	}

	.right {
		overflow: hidden;
	}

	.strip {
		line-height: 2.5;
		margin-top: 40px;
		font-size: px2rem(18);
		text-align: center;
		border-top: 1px solid rgba(112, 112, 112, 1);
		color: #707070;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 80px;

		img {
			width: 20px;
			/* no */
			height: 22px;
			/* no */
		}
	}

	.copyright {
		margin-left: 30px;
		color: #707070;
		display: inline-flex;
		align-items: center;
		column-gap: 5px;
	}

	.copyright:hover {
		color: gray;
	}
}

@media screen and (min-width: 1440px) {

}

@media screen and (max-width: 1100px) {
	.footer-container {
		.company-name {
			font-size: 1rem;
			margin-top: 1rem;
		}
		.company-theory {
			font-size: px2rem(16);
		}
	}
}

@media screen and (max-width: 768px) {
	.footer-container {
		.content {
			.company-name {
				margin-top: 1.5rem;
			}

			.company-theory {
				margin-top: 1rem;
			}

			.contact-container {
				margin-top: 1rem;
			}
		}
		.contact-person .person img {
			width: 12px;
			height: 12px;
		}

		.strip img {
			width: 10px;
			height: 11px;
		}

		.contact-container {
			grid-template-columns: 1fr;
			row-gap: 10px;
		}

		.contact-box {
			gap: 10px;
			.tel-value{
				white-space: break-spaces;
			}
		}

		.contact-person {
			width: 100%;
			row-gap: 10px;

			.person {
				row-gap: 10px;

				.wx {
					justify-content: flex-end;
				}
			}
		}

		.strip {
			margin-top: 20px;
			flex-direction: column;
		}
	}
}
.call{
	display: flex;

}
