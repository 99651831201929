
.banner-title {
  color: #3384e8;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 64px;
  text-align: left;
  text-align: center;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 24px;
  margin-bottom: 64px;
  text-align: left;
  line-height: 2;
  font-family: "Source Han Sans CN-Medium", serif;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 18px;
    white-space: normal;
    word-wrap: break-word;
  }
}
.support {
  padding: 80px 0;
  .list {
    margin-top: 112px;
    .item {
      margin: 0 auto;
      margin-bottom: 40px;
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      &:nth-child(2n) {
        flex-direction: row-reverse;
        .general {
          margin-left: 0;
          margin-right: 40px;
        }
      }
      .general {
        flex: 1;
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        margin-left: 40px;
        overflow: hidden;
        @media screen and (max-width: 767px) {
          width: 100%;
          flex: auto;
        }
      }
      .left {
        flex: 1;
        .img {
          width: 100%;
        }
      }
      .right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;

        .title {
          color: white;
          font-size: 36px;
          margin-bottom: 30px;
          width: 100%;
          @media screen and (max-width: 1000px) {
            font-size: 24px;
          }
        }
        .brief {
          font-size: 24px;
          color: rgba($color: white, $alpha: 0.6);
          line-height: 2;
          @media screen and (max-width: 1000px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
