
.hover-menu {
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
  border-radius: 8px 0px 0px 8px;
  position: fixed;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 999;
}
.hover-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 86px;
  color: white;
  cursor: pointer;
  position: relative;
}
.hover-menu-item:hover {
  background: rgba(191, 222, 255, 0.6);
  border-radius: 8px 0px 0px 8px;
}
.hover-menu-txt {
  font-size: 12px;
  margin-top: 8px;
}
.hover-menu-wechat:hover .hover-menu-wechat-hide {
  display: block;
}
.hover-menu-wechat-hide {
  display: none;
  position: absolute;
  width: 200%;
  right: 100%;
  top: 0;
  box-shadow: 0 0 10px 2px #ccc;
}
.hover-menu-wechat-qr {
  width: 100%;
}
.hover-menu-phone:hover .hover-menu-phone-hide {
  display: flex;
}
.hover-menu-phone-hide {
  background: white;
  display: none;
  font-size: 36px;
  color: #3295f0;
  position: absolute;
  right: 100%;
  height: 100%;
  top: 0;
  box-shadow: 0 0 10px 2px #ccc;
  white-space: nowrap;
  align-items: center;
  padding: 0 20px;
}
.hover-pop {
  display: none;
}
.hover-pop-back {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(10, 10, 10, 0.6);
  z-index: 999;
}
.hover-pop-content {
  background: no-repeat center/cover;
  width: 800px;
  height: 612px;
  padding: 0 120px;
  padding-top: 32px;
  box-sizing: border-box;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
}
.hover-pop-title {
  font-weight: 500;
  color: #3097ef;
  font-size: 36px;
  text-align: center;
  margin-bottom: 60px;
}
.hover-pop-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.hover-pop-icon {
  width: 32px;
  flex-shrink: 0;
}
.hover-pop-txt {
  width: 90px;
  font-size: 20px;
  color: rgba(48, 151, 239, 1);
  margin: 0 16px;
  flex-shrink: 0;
  -moz-text-align-last: justify;
       text-align-last: justify;
  position: relative;
}
.hover-pop-txt::after {
  content: ":";
  position: absolute;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.hover-pop-input {
  flex-grow: 1;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #cccccc;
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
  outline: none;
}
.hover-pop-check-icon {
  width: 138px;
  height: 40px;
  margin-right: 16px;
  cursor: pointer;
}
.hover-pop-demand {
  width: 138px;
  height: 40px;
  margin-right: 16px;
  color: rgba(48, 151, 239, 1);
  text-align: right;
  position: relative;
  align-self: flex-start;
}
.hover-pop-demand::after {
  content: ":";
  position: absolute;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 100%;
}
.hover-pop-area {
  height: 140px;
  resize: none;
  padding-top: 10px;
  box-sizing: border-box;
}
.hover-pop-button {
  font-size: 24px;
  color: white;
  width: 397px;
  height: 48px;
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  line-height: 48px;
  margin: 0 auto;
  margin-top: 56px;
  cursor: pointer;
}
.hover-pop-success {
  display: none;
  width: 657px;
  height: 520px;
  padding: 40px 0;
  text-align: center;
  box-sizing: border-box;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
}
.hover-pop-success-img {
  width: 180px;
  margin: 0 auto;
}
.hover-pop-success-title {
  color: rgba(29, 218, 103, 1);
  font-size: 36px;
  margin-top: 40px;
}
.hover-pop-success-subtitle {
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 85px;
}
.hover-pop-success-info {
  display: flex;
  align-items: center;
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  justify-content: center;
}
.hover-pop-success-phone {
  margin-right: 8px;
}
.hover-pop-success-sp {
  font-size: 24px;
  color: rgba(48, 151, 239, 1);
  margin-left: 16px;
}
.hover-pop-close {
  position: absolute;
  width: 16px;
  right: 24px;
  top: 24px;
  cursor: pointer;
}
