
$baseSize: 24;
@function px2rem($px) {
  @if calc($px / $baseSize * 0.75) <= 0.5 {
    @return 14px;
  }
  @return calc($px / $baseSize * 0.75rem);
}
.solution {
  .banner-title {
    color: white;
    font-size: px2rem(48);
    font-weight: bold;
    margin-bottom: px2rem(64);
    color: rgba(51, 132, 232, 1);
  }
  .banner-subtitle {
    color: white;
    font-size: 24px;
    margin-bottom: px2rem(64);
    text-align: left;
    line-height: 2;
  }
  .banner-txt {
    background: #0f52fb;
    border-radius: 4px 4px 4px 4px;
    padding: px2rem(18) px2rem(27);
    color: white;
    font-size: px2rem(24);
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .solution {
    .banner-title {
      font-size: 24px;
      margin-bottom: px2rem(32);
      text-align: center;
    }
    .banner-subtitle {
      width: 100%;
      font-size: 18px;
    }
  }
}

.manifest {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    .item {
      background: white;
      padding: 30px 0;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      width: calc((100% - 96px) / 5);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
      margin-bottom: 24px;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
.ability {
  padding: 80px 0;
  .list {
    display: flex;
    margin-top: 80px;
    .item {
      background: white;
      padding: 30px 0;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      width: calc((100% - 210px) / 4);
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 70px;
      position: relative;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(1) {
        background: url(../asset/solution/n_sol_42.png) no-repeat center/cover;
      }
      &:nth-child(2) {
        background: url(../asset/solution/n_sol_33.png) no-repeat center/cover;
      }
      &:nth-child(3) {
        background: url(../asset/solution/n_sol_31.png) no-repeat center/cover;
      }
      &:nth-child(4) {
        background: url(../asset/solution/n_sol_32.png) no-repeat center/cover;
      }
      &:hover {
        .content {
          top: 100%;
          opacity: 0;
        }
        .hide {
          margin-top: -84px;
          opacity: 1;
        }
      }
      .content {
        color: white;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all 0.5s;
        backdrop-filter: blur(10px);
        background: rgba($color: white, $alpha: 0.3);
        text-align: center;

        .name {
          font-size: 24px;
          display: flex;
          align-items: center;
          margin-top: 128px;
          justify-content: center;
          .icon {
            width: 22px;
            margin-right: 16px;
          }
          .txt {
          }
        }
        .brief {
          font-size: 14px;
          margin-top: 36px;
          line-height: 1.5;
        }
      }
      .hide {
        opacity: 0;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: rgba(51, 132, 232, 1);
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background: white;
        transition: all 0.5s;
        line-height: 1;
        .hideicon {
          width: 24px;
          margin-right: 16px;
        }
      }
    }
  }
}

.serv {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 80px;
    .item {
      padding: 30px 0;
      text-align: center;
      &:nth-child(5n) {
        margin-right: 0;
      }
      .icon {
        width: 180px;
      }
      .title {
        color: rgba(51, 51, 51, 1);
        margin-top: 42px;
        font-size: 24px;
      }
      .brief {
        margin-top: 16px;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
      }
    }
  }
}
