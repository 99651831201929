
.nav {
  font-size: 0;
  position: relative;
  width: 100%;
  top: 0;
  padding-top: 37.835%;
}

.banner {
  width: 100%;
}

.content {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: white;
  position: fixed;
  z-index: 999;
  height: 100px;
  @media (max-width: 767px) {
    height: 60px;
  }
}
.content-limit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 138px;
  pointer-events: none;
  @media (max-width: 767px) {
    width: 80px;
  }
}
.slogan {
  font-size: 14px;
  color: #666666;
  margin-left: 40px;
  flex-shrink: 0;
  line-height: 2;
}

.tabs {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  @media (max-width: 767px) {
    flex-direction: row-reverse;
  }
}
.list {
  color: white;
  font-size: 18px;
  display: flex;
  align-items: stretch;
  height: 100px;

  @media (max-width: 767px) {
    display: block;
    position: absolute;
    right: 0;
  }
}
.query {
  margin-left: 120px;
  @media (max-width: 767px) {
    margin-left: 20px;
  }
  .button {
    width: 133px;
    height: 100px;
    display: none;
    cursor: pointer;

    // .button-icon {
    //   width: 70px;
    //   @media (max-width: 767px) {
    //     width: 40px;
    //   }
    // }
    @media (max-width: 767px) {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.phone-content {
  display: flex;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.phone {
  font-size: 24px;
  color: #3295f0;
  display: flex;
  align-items: center;
  margin-left: 60px;
  white-space: nowrap;
  @media (max-width: 767px) {
    margin-left: 10px;
    font-size: 10px;
  }
  .phone-icon {
    width: 26px;
    margin-right: 8px;
    @media (max-width: 767px) {
      width: 12px;
    }
  }
}

.item {
  padding: 0 34px;
  height: 100px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  white-space: nowrap;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 767px) {
    width: auto;
    padding: 0 20px;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    height: 60px;
    font-size: 14px;
  }
  &:hover {
    .child {
      display: block;
    }
  }
  .child {
    position: absolute;
    top: 82%;
    padding-top: 16px;
    left: -14px;
    right: -14px;
    display: none;
    .child-inner {
      background: white;
      // background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
      padding: 4px;
      border-radius: 4px;
    }
    @media screen and (max-width: 767px) {
      left: auto;
      right: 100%;
      top: 0;
      padding-top: 0;
      padding-right: 30px;
      height: 60px;
    }
    .child-item {
      padding: 0 12px;
      height: 30px;
      font-size: 16px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: start;
      font-weight: normal;
      margin-bottom: 4px;
      border-radius: 4px;
      .nav-icon {
        width: 18px;
        margin-right: 4px;
        position: relative;
        top: 1px;
      }
      &:hover {
        color: #3384e8;
      }
      &:last-child {
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
        height: 60px;
      }
    }
    .child-active {
      // color: #3384e8;
    }
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 4px;
    background-color: #3384e8;
    transition: width 0.3s ease;
  }
}

.item:hover,
.active {
  color: #3384e8;
  &::after {
    width: 50%;
  }
}

.hover {
  position: absolute;
  left: 50%;
  // top: 100px;
  // height: calc(100% - 100px);
  transform: translateX(-50%);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -30px;
  .txt {
    line-height: 1;
    text-align: center;
    padding: 0 20px;
  }
  .digital {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
.banner-list {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 100px;
  box-sizing: border-box;
  .banner-item {
    width: 100%;
    height: calc(100% - 100px);
    position: absolute;
    transition: 0.5s all;
    text-align: left;
    .text-container {
      position: absolute;
      // width: 100%;
      // height: 100%;
      text-align: center;
      color: #000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .top-text {
        font-size: 32px;
        font-family: Microsoft YaHei;
      }
      .title {
        background: linear-gradient(to right, #2f74ff 45%, #1eb9e9 57%);
        font-size: 70px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        margin: 40px 0 25px 0;
        // min-width: 62.5rem;
      }
      .line {
        height: 2px;
        margin: 0 22%;
        background: linear-gradient(
          90deg,
          rgba(0, 130, 255, 0) 0%,
          rgba(0, 130, 255, 0.99) 50%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      .desc {
        margin-top: 25px;
        font-size: 24px;
        font-family: Microsoft YaHei;
      }
    }
    .text-container2 {
      position: absolute;
      // padding-left: 14vw;
      left: 13%;
      top: 22%;
      .top-text {
        font-size: 32px;
        font-family: Microsoft YaHei;
      }
      .title {
        color: #0082ff;
        font-size: 70px;
        font-weight: bold;
        margin: 40px 0 25px 0;
        // min-width: 62.5rem;
      }
      .line {
        height: 2px;
        // width: 80%;
        background: linear-gradient(
          to right,
          rgba(0, 130, 255, 0.99) 0%,
          #1eb9e9,
          40%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      .desc {
        margin-top: 25px;
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
      }
      .button {
        margin-top: 1.5625rem;
        width: 8.5938rem;
        height: 3.125rem;
        text-align: center;
        line-height: 3.125rem;
        color: #0082ff;
        font-size: 1.25rem;
        border: 1px solid #6eb7ff;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          color: #fff;
          background: #0082ff;
        }
      }
    }
    .more-button {
      position: absolute;
      left: 200px;
      bottom: 200px;
      color: #0082ff;
      font-size: 24px;
      width: 165px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border: 1px solid #6eb7ff;
      border-radius: 5px;
      cursor: pointer;
    }
    .banner-limit {
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 8.5%;
      box-sizing: border-box;
      position: relative;
      top: 25%;
      .banner-buttons {
        display: flex;
        align-items: center;
        gap: 40px;
      }
      .banner-button {
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 230px;
        height: 64px;
        border-radius: 40px;
        margin-top: 30px;
        cursor: pointer;
        font-weight: bold;
        box-sizing: border-box;
      }
      .banner-button1 {
        background-color: transparent;
        color: white;
        border: 1px solid white;
      }
      .banner-button1-c {
        border-color: #096dd9;
        color: #096dd9;
      }
      .banner-button2 {
        color: rgba(9, 109, 217, 1);
        background-color: white;
      }
      .banner-button2-c {
        color: white;
        background-color: #096dd9;
      }
    }
  }
}
.indicator {
  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  .indicator-item {
    width: 35px;
    height: 7px;
    background: #afb5be;
    cursor: pointer;
    &:hover {
      background: #3192ee;
    }
  }
  .indicator-item-active {
    background: #3192ee;
  }
}
@media screen and (max-width: 1792px) {
  .text-container,.text-container2 {
    .top-text {
      font-size: 1.25rem !important;
    }
    .line {
      height: 1px !important;
    }
    .title {
      font-size: 2.0833rem !important;
    }
    .desc {
      font-size: .9375rem !important;
    }
    .button {
      width: 6.5104rem !important;
      height: 2.0833rem !important;
      line-height: 2.0833rem !important;
      font-size: .9375rem !important;
    }
  }
}
