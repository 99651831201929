
.container {
  width: 100%;
  height: 85px;
  background-color: #353a5bd8;
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.title {
  color: #fff;
}
.code-img {
  background: white;
  height: 42px;
  margin: 0 20px;
}
.submit-btn {
  background: #2f74ff;
  color: #fff;
  font-size: 18px;
  padding: 12px;
  cursor: pointer;
}
.input-css {
  flex-grow: 1;
  background: #ffffff;
  border: 1px solid #cccccc;
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
  outline: none;
  margin-left: 20px;
}
.mask {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(10, 10, 10, 0.6);
}
.hover-pop-success {
  display: inherit;
  width: 657px;
  height: 480px;
  padding: 40px 0;
  text-align: center;
  box-sizing: border-box;
  border-radius: 16px;

  .hover-pop-success-img {
    width: 180px;
    margin: 0 auto;
  }
  .hover-pop-success-title {
    color: rgba(29, 218, 103, 1);
    font-size: 36px;
    margin-top: 20px;
  }
  .hover-pop-success-subtitle {
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 25px;
  }
  .hover-pop-success-info {
    display: flex;
    align-items: center;
    color: rgba(102, 102, 102, 1);
    font-size: 18px;
    justify-content: center;
  }
  .hover-pop-success-phone {
    margin-right: 8px;
  }
  .hover-pop-success-sp {
    font-size: 24px;
    color: rgba(48, 151, 239, 1);
    margin-left: 16px;
  }
  .hover-pop-close {
    position: absolute;
    width: 20px;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }
}
