
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
@function px2rem_pure($px) {
	@return calc($px / $baseSize * 0.75rem);
}
.aptitude {
	padding-top: px2rem_pure(100);
	padding-bottom: px2rem_pure(50);
	.title-container {
		display: flex;
		flex-direction: column;
	}
	.mt-title {
		align-self: center;
		font-size: px2rem(48);
		font-weight: bold;
		color: #333333;
		.mt-title-value {
			&::after {
				display: block;
				content: '';
				width: 100%;
				height: 15px;
				background: #0F52FB;
				margin-top: 6px;
			}
		}
	}
	.mt-subtitle {
		align-self: center;
		font-size: px2rem(18);
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: center;
		text-align: center;
		padding: px2rem_pure(26) 0 px2rem_pure(50) 0;
		padding-left: px2rem_pure(24);
	}
	.ap-list {
		display: flex;
		flex-direction: column;
		row-gap: px2rem_pure(160);
		.ap-line, .ap-line .main{
			width: 100%;
			display: flex;
			column-gap: px2rem_pure(112);
			justify-content: flex-end;
			box-sizing: border-box;
			position: relative;
		}
		.ap-line {
			&:nth-child(2n-1) {
				justify-content: flex-start;
				color: #FFFFFF;
				& .main {
					justify-content: flex-start;
					color: #FFFFFF;
				}
				&:before {
					left: 0;
					bottom: 0;
					transform: translateY(30%);
					content: '';
					display: block;
					width: 100%;
					height: px2rem_pure(320);
					background: #0F52FB;
					position: absolute;
					z-index: 0;
				}
			}
		}
		.ap-item {
			position: relative;
			.ap-item-inner {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				row-gap: px2rem_pure(40);
			}
			.ap-item-image {
				flex-shrink: 0;
				aspect-ratio: 280 / 392;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			.ap-item-title {
				font-size: px2rem(20);
				font-weight: 500;
				line-height: 1.6;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.aptitude {
		.mt-title {
			font-size: 24px;
		}
		.ap-list {
			.ap-line .main {
				column-gap: 10px!important;
				justify-content: center;
			}
			.ap-line:last-child .main {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				row-gap: 20px;
				.ap-item {
					width: 100%;
					flex: 1;
					.ap-item-inner {
						row-gap: 10px;
					}
				}
			}
			.ap-item {
				width: 33%;
				flex: 1;
				.ap-item-image {
					width: 100%;
				}
				.ap-item-title {
					font-size: 16px;
					white-space: pre-wrap;
					text-align: center;
				}
			}
		}
	}
}
