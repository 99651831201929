
.partner {
  padding: 80px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  &::after {
    content: "";
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  .list {
    position: relative;
    height: 190px;
    margin-top: 50px;
    overflow: hidden;
    .item {
      position: absolute;
      width: 282px;
      height: 190px;
      .img {
        object-fit: contain;
      }
    }
  }
  .button {
    color: white;
    font-size: 24px;
    width: 217px;
    height: 61px;
    margin: 0 auto;
    margin-top: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    .icon {
      width: 30px;
    }
  }
}
