
.banner-title {
  color: white;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 64px;
  .banner-title-sp {
    color: #3384e8;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 24px;
  margin-bottom: 77px;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 18px;
  }
}
.banner-txt {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px 4px 4px 4px;
  padding: 18px 27px;
  color: #3384e8;
  font-size: 24px;
  display: inline-block;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 16px;
  }
}
.banner-digital {
  background: #FFFFFF;
  box-shadow: 0px 3px 16px 0px rgba(136,131,131,0.18);
  border-radius: 10px;
  height: 160px;
  width: 90%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: #000;
  padding: 0 65px;
  justify-content: space-between;
  .banner-item {
    display: flex;
    .num {
      color:  #0075FF;
      font-size: 36px;
      text-align: left;
      margin-left: 35px;
      font-weight: bold;
      .sp {
        font-size: 36px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    .digital-txt {
      padding-left: 6px;
      color: #000;
      font-size: 18px;
      box-sizing: border-box;
      margin-top: 12px;
      font-weight: 500;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 200px;
      height: 100px;
    }
  }
}

.core {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);

  .list {
    line-height: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 391px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 80px;
    gap: 56px;
    .item {
      flex: 1;
      background: white;
      box-shadow: 3px 5px 20px 1px #ddecfa;
      padding: 0 74px;
      text-align: center;
      padding-top: 82px;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        transform: scale(1.2);
      }
      .icon {
        width: 90px;
        margin-bottom: 40px;
      }
      .title {
        font-size: 24px;
        color: #333333;
        font-weight: 500;
      }
      .brief {
        font-size: 14px;
        color: #666666;
        line-height: 2;
        margin-top: 40px;
      }
    }
  }
}
.strip {
  width: 100%;
  height: 324px;
  background: #0f51fa;
  margin-top: -500px;
  @media (max-width: 1250px) {
    visibility: hidden;
  }
}
.promise {
  padding: 80px 0;

  .list {
    display: flex;
    align-items: stretch;
    margin-top: 60px;
    height: 314px;
    gap: 40px;
    .item {
      position: relative;
      flex: 1;
      background: white;
      transition: all 0.5s;
      box-shadow: 5px 5px 70px 1px #e8e8e8;
      text-align: center;
      padding-top: 60px;
      .background {
        width: 130%;
        margin-top: 100px;
        margin-left: 40px;
      }

      .icon {
        height: 100px;
        margin-bottom: 40px;
      }
      .title {
        font-size: 24px;
        color: #333333;
        font-weight: 500;
        margin-bottom: 16px;
      }

      .brief {
        font-size: 14px;
        color: #666666;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      .item {
        flex: 1 1 50%;
        margin-bottom: 100px;
      }
    }
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      .item {
        flex: 0 1 90%;
        text-align: center;
      }
    }
  }
}
.process {
  background: rgba(221, 236, 250, 0.5);
  padding: 80px 0;
}
.advantage {
  background: #1a1a1a url(../asset/index/index_45.png) no-repeat center/cover;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0;
    .left {
      color: white;
      text-align: center;
      margin-right: 120px;
      flex-shrink: 0;
      .title {
        font-size: 36px;
        margin-bottom: 24px;
      }
      .brief {
        font-size: 24px;
      }
    }
    .right {
      display: flex;
      gap: 0 3%;
      flex-wrap: wrap;
      align-content: center;
      .item {
        width: 31%;
        display: flex;
        align-items: center;
        background: white;
        margin-bottom: 60px;
        padding: 48px;
        box-sizing: border-box;
        &:nth-child(3) ~ .item {
          margin-bottom: 0;
        }
        .img {
          width: 70px;
          margin-right: 24px;
        }
        .inner {
          .title {
            font-size: 24px;
            color: #333333;
            margin-bottom: 20px;
            font-weight: 500;
          }
          .brief {
            font-size: 14px;
            color: #333333;
            line-height: 2;
          }
        }
      }
    }
  }
}
.multiple {
  padding: 80px 0;

  .multiple-img {
    width: 100%;
    margin-top: 40px;
  }
}
.custom {
  padding: 193px 0 65px;

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    margin-top: 60px;
    .item {
      width: 22.5%;
      margin-bottom: 40px;
      box-shadow: 5px 5px 70px 1px #e8e8e8;
      .img {
        position: relative;
        background: no-repeat center/cover;
        height: 218px;

        .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .txt {
        text-align: center;
        padding: 24px 0 17px;
        background: white;
        .title {
          font-size: 24px;
          margin-bottom: 16px;
          font-weight: bold;
        }
        .brief {
          font-size: 14px;
          padding: 0 20px;
          line-height: 2;
        }
      }
    }
  }
}
.join {
  background: rgba(221, 236, 250, 0.5);
  padding: 80px 0;
  .list {
    gap: 90px 0;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    .item {
      position: relative;
      padding: 22px 0 40px;
      background: white;
      width: 800px;
      box-sizing: border-box;
      &:nth-child(2) {
        align-self: flex-end;
        text-align: right;
        .img {
          left: auto;
          right: 0;
        }
      }
      .img {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-50%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: white;
        box-shadow: 3px 5px 8px 1px rgba(165, 165, 165, 0.16);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        padding: 0 152px;
        font-size: 28px;
        color: #3384e8;
      }
      .brief {
        font-size: 24px;
        color: #666666;
        padding: 0 40px;
        margin-top: 40px;
      }
    }
  }
}
.partner {
  padding: 80px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  &::after {
    content: "";
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  .list {
    position: relative;
    height: 190px;
    margin-top: 50px;
    overflow: hidden;
    .item {
      position: absolute;
      width: 282px;
      height: 190px;
      .img {
        object-fit: contain;
      }
    }
  }
  .button {
    color: white;
    font-size: 24px;
    width: 217px;
    height: 61px;
    margin: 0 auto;
    margin-top: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    .icon {
      width: 30px;
    }
  }
}
