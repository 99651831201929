
$baseSize: 24;
@function px2rem($px) {
	@return calc($px / $baseSize * 0.75rem);
}
.merits {
	padding-top: px2rem(100);
	background: white;
	.title-container {
		display: flex;
		flex-direction: column;
	}
	.mt-title {
		align-self: flex-end;
		font-size: px2rem(48);
		font-weight: bold;
		color: #333333;
		.mt-title-value {
			&::after {
				display: block;
				content: '';
				width: 100%;
				height: 15px;
				background: #0F52FB;
				margin-top: 6px;
			}
		}
	}
	.mt-subtitle {
		align-self: flex-end;
		font-size: px2rem(18);
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: flex-start;
		padding: px2rem(26) 0 px2rem(50) 0;
		padding-left: px2rem(24);
	}
	.mt-description {
		align-self: flex-end;
		font-size: px2rem(24);
		font-weight: 500;
		color: #666666;
		white-space: pre-wrap;
		text-align: right;
		line-height: 1.5;/* no */
	}
	.content-container {
		padding: px2rem(118) 0 px2rem(99) 0;
		background: #1A1A1A;
		margin-top: px2rem(40);
	}
	.mt-list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		row-gap: px2rem(80);
		.mt-li {
			display: flex;
			justify-content: flex-start;
			.content {
				width: px2rem(276);
				flex-shrink: 0;
				position: relative;
				padding: px2rem(22);
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				align-items: center;
				.li-inner {
					background: rgba(26, 26, 26, .7);
					width: 100%;
					height: 100%;
					z-index: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					align-items: center;
					padding: px2rem(22) px2rem(13);
					box-sizing: border-box;
				}
			}
			&:nth-child(8n+1), &:nth-child(8n+2), &:nth-child(8n+3), &:nth-child(8n+4) {
				justify-content: flex-end;
			}

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: contain;
				z-index: 0;
			}
		}
		.index {
			font-size: px2rem(36);
			font-weight: 400;
			color: #FFFFFF;
		}
		.title {
			margin-top: px2rem(32);
			font-size: px2rem(20);
			font-weight: 400;
			color: #F7F7F7;
			white-space: pre-wrap;
			text-align: center;
		}
	}
}
@media screen and (max-width: 1100px){
	.merits {
		.mt-list {
			.mt-li {
				.content {
					width: px2rem(240);
					padding: px2rem(22) px2rem(10);
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.merits {
		.mt-title {
			font-size: 24px;
		}
		.mt-description {
			font-size: 16px;
		}
		.content-container {
			padding: 30px 0
		}
		.mt-list {
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;
			.mt-li .content {
				width: 100%;
				height: 120px;
				padding: 10px;
				box-sizing: border-box;
				.li-inner {
					padding: 0;
				}
			}
			.title{
				margin-top: 4px;
				font-size: 10px;
			}
		}
	}
}
