@font-face {
    font-family: AlibabaPuHuiTi-3-55-Regular;
    src:url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.eot) format('embedded-opentype'),
    url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.otf) format('opentype'),
    url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.ttf) format('TrueType'),
    url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.woff) format('woff'),
    url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.woff2) format('woff2');
}
@font-face {
    font-family: Source Han Sans CN-Medium;
    src:url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.eot) format('embedded-opentype'),
    url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.otf) format('opentype'),
    url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.ttf) format('TrueType'),
    url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.woff) format('woff'),
    url(https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/font/AlibabaPuHuiTi-3-55-Regular.woff2) format('woff2');
}

* {
    font-family: AlibabaPuHuiTi-3-55-Regular, serif;
}

.main {
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
}

@media screen and (max-width: 1500px) {
    .main {
        padding: 0 20px;
    }
}

@media screen and (min-width: 1440px) {
    html {
        font-size: 24px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1440px) {
    html {
        font-size: 24px;
    }
}

@media screen and (max-width: 768px) {
    html {
        font-size: 24px;
    }
}



