
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
.support {
	background: #F5F5F5;
	//min-height: 500px;
	padding-top: px2rem(100);
	padding-bottom: px2rem(75);
	.title {
		display: flex;
		justify-content: flex-start;
		font-size: px2rem(48);
		font-weight: bold;
		color: #333333;
		padding-left: px2rem(24);

		.title-value {
			&::after {
				display: block;
				content: '';
				width: 100%;
				height: 15px;
				background: #0F52FB;
				margin-top: 6px;
			}
		}
	}
	.sub-title {
		font-size: px2rem(18);
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: flex-start;
		padding: px2rem(26) 0 px2rem(50) 0;
		padding-left: px2rem(24);
	}
	.description {
		font-size: px2rem(24);
		font-weight: 500;
		color: #666666;
		display: flex;
		justify-content: flex-start;
		padding-left: px2rem(24);
	}

	.support-list {
		display: flex;
		margin-top: px2rem(40);
	}
	.support-item {
		aspect-ratio: 561 / 452;
		width: 100%;
		background: #5381f8;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		box-sizing: border-box;
		padding-bottom: 180px;
		&:hover {
			background: #0F51FA;
		}
		.si-title {
			font-size: px2rem(36);
			font-weight: 500;
			color: #FFFFFF;
			margin-top: px2rem(67);
		}
		.si-subtitle {
			font-size: px2rem(24);
			font-weight: 400;
			color: rgba(255,255,255,0.6);
			margin-top: px2rem(17);
		}
		.si-description {
			font-size: px2rem(16);
			font-weight: 400;
			color: #FFFFFF;
			line-height: 2;/* no */
			margin-top: px2rem(42);
			text-align: center;
			font-family: AlibabaPuHuiTi-3-55-Regular, serif;
			white-space: pre-wrap;
		}
		img {
			position: absolute;
			left: 50%;
			bottom: px2rem(60);
			width: px2rem(93);
			height: px2rem(89);
			transform: translateX(-50%);
		}
	}
}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 768px) {
	.support {
		.title {
			font-size: 24px;
		}
		.support-list {
			flex-direction: column;
			gap: 20px;
		}
		.support-item {
			height: 250px;
			img {
				width: 40px;
				height: 40px;
				bottom: 10px;
			}
		}
	}
}
