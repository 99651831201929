
.banner-title {
  color: #3384e8;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 64px;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 24px;
  text-align: left;
  line-height: 2;
  font-family: "Source Han Sans CN-Medium", serif;
  @media screen and (max-width: 767px) {
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    font-size: 18px;
  }
}
.range {
  padding: 80px 0;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .box {
      flex: 0 1 33%;
      box-sizing: border-box;
      margin: 54px 0 0;
      padding: 0 27px;
      .fancy {
        box-shadow: 3px 5px 20px 1px rgba(125, 125, 125, 0.16);
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          width: 100%;
        }
      }
      .name {
        font-size: 24px;
        color: #3384e8;
        height: 77px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media screen and (max-width: 1200px) {
        flex-basis: 33%;
      }
      @media screen and (max-width: 900px) {
        flex-basis: 50%;
      }
    }
  }
}
.management {
  padding: 70px 0 0;
  // background: #f5f5f5;
  background: url("../asset/development/n_dev_10.png") no-repeat center/cover;

  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-bottom: 60px;
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        width: 258px;
        height: 166px;

        .icon {
          width: 80px;
        }
      }
      .name {
        font-size: 24px;
        color: #333333;
        margin-top: 33px;
        text-align: center;
        font-weight: bold;
      }
    }
    @media screen and (max-width: 1000px) {
      justify-content: center !important;
    }
  }
  .list1 {
    margin-top: 80px;
    justify-content: flex-start;
    .item {
      margin-right: 47px;
      @media screen and (max-width: 532px) {
        margin-right: 0;
      }
    }
  }
  .list2 {
    justify-content: flex-end;
    .item {
      margin-left: 47px;
      @media screen and (max-width: 532px) {
        margin-left: 0;
      }
    }
  }
}
.process {
  background: #ffffff;
  padding: 80px 0;
}
.tailor {
  padding: 80px 0;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    .item {
      width: calc((100% - 384px) / 4);
      margin-right: 128px;
      margin-bottom: 40px;
      transform: translateX(40px);
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(4) ~ .item {
        transform: translateX(0);
      }
      .img {
      }
      .name {
        color: #333333;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 2;
        text-align: center;
        margin: 0;
      }
    }
  }
}
.why {
  padding: 80px 0;
  background: url(../asset/development/n_dev_17.png) no-repeat center/cover;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    justify-content: center;
    .item {
      background: white;
      width: 330px;
      margin-right: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      widows: 330px;
      height: 182px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3) ~ .item {
        margin-top: 80px;
      }
      .title {
        display: flex;
        align-items: center;
        color: #3384e8;
        font-size: 24px;
        .icon {
          width: 24px;
          margin-right: 8px;
        }
      }
      .brief {
        color: #333333;
        font-size: 14px;
        line-height: 2;
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}
.inventory {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    .item {
      width: calc((100% - 96px) / 5);
      margin-bottom: 24px;
      background: white;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-size: 24px;
      color: #333333;
      font-weight: 500;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(5n) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        margin-right: 16px;
      }
    }
  }
}
