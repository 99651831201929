
.banner-title {
  color: white;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 64px;
  text-align: left;
  text-align: center;
  color: rgba(51, 132, 232, 1);
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 27px;
  margin-bottom: 64px;
  text-align: left;
  line-height: 2;
  font-family: "Source Han Sans CN-Medium", serif;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 18px;
    white-space: normal;
    word-wrap: break-word;
  }
}
.process {
  background: #ffffff;
  padding: 80px 0;
  padding-top: 0;
}

.why {
  padding: 80px 0;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: center;
    .item {
      background: url(../asset/development/n_dev_aplt_22.png) no-repeat
        center/contain;
      width: 280px;
      height: 280px;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      margin-right: 100px;
      &:nth-child(3) ~ .item {
        transform: translateX(200px);
      }
      .icon {
        height: 40px;
      }
      .txt {
        font-size: 14px;
        color: white;
        width: 100%;
        text-align: center;
        margin-top: 24px;
      }
    }
  }
}

.genius {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: center;
    .item {
      width: calc((100% - 120px) / 4);
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      margin-right: 40px;
      box-shadow: 5px 5px 70px 1px #e8e8e8;
      padding: 40px 0;
      margin-bottom: 40px;
      background: white;

      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(4) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        width: 130px;
      }
      .title {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        width: 100%;
        text-align: center;
        margin-top: 24px;
        margin-top: 80px;
      }
      .brief {
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        line-height: 2;
        margin: 0;
        margin-top: 15px;
      }
    }
  }
}

.usual {
  padding: 80px 0;
  background: url(../asset/development/n_dev_aplt_27.png) no-repeat center/cover;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: center;
    align-content: center;
    .item {
      width: calc((100% - 280px) / 5);
      margin-right: 70px;
      box-shadow: 3px 5px 20px 1px #e0e0e0;
      margin-bottom: 40px;
      background: white;
      text-align: center;
      padding-bottom: 20px;

      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(5) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        width: 100%;
      }
      .title {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
      .brief {
        color: rgba(51, 51, 51, 1);
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
        margin-top: 10px;
      }
    }
  }
}

.prin {
  padding: 80px 0;
  .list {
    display: flex;
    margin-top: 80px;
    .item {
      width: 33%;
      background: linear-gradient(180deg, #bfdeff 0%, #d2f2ff 100%);
      text-align: center;
      padding-top: 78px;
      padding-bottom: 100px;
      &:hover {
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        .title {
          color: rgba(255, 255, 255, 1);
          &::after {
            background: #ffffff;
          }
        }
        .subtitle {
          color: rgba(255, 255, 255, 1);
        }
        .brief {
          color: rgba(255, 255, 255, 1);
          border-color: white;
        }
      }
      .icon {
        width: 100%;
      }
      .title {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        width: 100%;
        margin-top: 20px;
        position: relative;
        padding-bottom: 24px;
        &::after {
          content: "";
          position: absolute;
          width: 71px;
          height: 2px;
          background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
          border-radius: 2px 2px 2px 2px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .subtitle {
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        margin-top: 64px;
      }
      .brief {
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        margin-top: 90px;
        padding: 16px 54px;
        border: 1px solid #3295f0;
        display: inline-block;
      }
    }
  }
}
.inventory {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    .item {
      width: calc((100% - 96px) / 5);
      margin-bottom: 24px;
      background: white;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-size: 24px;
      color: #333333;
      font-weight: 500;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(5n) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        margin-right: 16px;
      }
    }
  }
}
