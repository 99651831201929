
body {
  overflow-x: hidden;
}

.hideTxt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#root {
  margin: 0 auto;
  padding-bottom: 85px;
}
.content-limit {
  max-width: 1440px;
  margin: 0 auto;
}
